
















































































































































































































































































































































































// 邀请图片
/deep/ .van-action-sheet{
	max-height:100%;
}
/deep/ .van-popup--bottom {
	background: #fff;
	.van-action-sheet__content{
		border-radius:0;
	}
	.van-swipe-item{
		border-radius:0;
	}
}
.content{
	width:94%;
	margin:0 auto;
	.prev,.next{
		padding: 0.12rem;
		height:2rem;
		background: #D8D8D8;
		color: #555;
		font-size: 0.3rem;
		font-weight: 600;
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		top: calc(50% - 2.2rem);
		z-index: 1;
	}
	.prev{
		left: 20px;
	}
	.next{
		right: 20px;
	}
	.shareBox{
		width:100%;
		display:flex;
		flex-direction: column;
		align-items:center;
		position:relative;
		.bg{
			font-size:0;
			img {
				width:100%;
			}
		}
		.con{
			position:absolute;
			top:0.34rem;
			left:0;
			right:0;
			.shareTitle {
				width:50%;
				margin:auto;
				img {
					width:100%;
				}
			}
			.tableBox {
				width:88%;
				margin:auto;
				font-size:0.2rem;
				margin-top:-0.28rem;
				text-align:center;
				position:relative;
				.highlight{
					position:absolute;
					left:18%;
					top:-0.2rem;
					width:41%;
					height:calc( 100% + 0.4rem );
					background: linear-gradient(135deg, #DCC297 0%, #FFE5BB 24%, #FFE6BC 56%, #C3A876 100%);
					border-radius: 8px;
				}
				img {
					width:0.24rem;
				}
				.tr{
					display:flex;
					position:relative;
					>div{
						display:flex;
						align-items:center;
						justify-content:center;
						text-align:left;
						padding:0.08rem 0.14rem;
						box-sizing:border-box;
						background:#372618;
					}
					&:first-child .sub{
						border-top-left-radius: 8px;
					}
					&:first-child .comm{
						border-top-right-radius: 8px;
					}
					&:last-child .sub{
						border-bottom-left-radius: 8px;
					}
					&:last-child .comm{
						border-bottom-right-radius: 8px;
					}
					.sub{
						width:18%;
						color:#B08F6F;
						text-align:center;
					}
					.hailang{
						width:41%;
						color:#56391E;
						background:transparent;
						justify-content:flex-start;
						&.subTitle{
							justify-content:center;
						}
						img {
							margin-right:0.12rem;
						}
					}
					.comm{
						width:41%;
						color:#fff;
						justify-content:flex-start;
						&.subTitle{
							color:#AB8A6B;
							justify-content:center;
						}
						img {
							margin-right:0.12rem;
						}
					}
				}
			}
		}
		.shareBottom{
			position:absolute;
			bottom: 0.2rem;
			left: 0;
			right: 0;
			margin: 0 0.4rem;
			display: flex;
			align-items: center;
			.op_container2 {
				flex: 1;
				text-align: left;
			}
			.yaoQingStyle {
				color: #EFBA0D;
				font-size: 12px;
				span{
					font-family: PingFangSC-Medium;
					font-weight: 500;
				}
				&:last-child {
					margin-top: 0.14rem;
				}
			}
			/deep/ .codeImgStyle2 {
				width: 1.35rem;
				height: 1.35rem;
				font-size:0;
				img {
					width: 100%;
					height: 100%;
				}
			}
		}
	}
	#shareBox2{
		width: 75.9%;
		margin: auto;
	}
	.btnBox{
		display:flex;
		flex-direction: column;
		align-items:center;
		font-size:0.28rem;
		margin-top:0.36rem;
		margin-bottom:0.36rem;
		.saveBtn{
			width:1.32rem;
			height:1.32rem;
			background: #F8F8F8;
			border-radius: 50%;
			display:flex;
			justify-content:center;
			align-items:center;
			margin-bottom:0.15rem;
			img {
				width:0.56rem;
			}
		}
	}
}

.btn1,
.btn2 {
	color: #000;
	border: 0;
	font-size: 0.28rem;
	background: none;
	padding: 0;
	margin-right: 0.36rem;
}

/*.yaoQingStyle {*/
/* position: absolute;
            bottom: 1.2rem;
            left: 0rem auto; */
/*margin-top: 11rem;*/
/*}*/

.titlePStyle {
	/* margin-bottom: 0.2rem; */
	margin-top: 0.2rem;
	/* padding-top: 0.1rem; */
	margin-right: 7rem;
	transform: rotate(180deg);
}

.qrcode {
	width: 0.3rem;
}

.fengXiangCode {
	position: absolute;
	bottom: 6.5rem;
	left: 3.2rem;
}

.spanStyle {
	margin-right: 7rem;
}

.op_container {
	flex: 1;
	text-align: left;
	margin-left: 0.5rem;
}

.title {
	height: 100%;
	background-color: #ffffff;
	&.bgVip {
		height: 100%;
		background-image: url('../../assets/imgs/share/poster.png');
	}

	.shareFooter {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		padding: 0.4rem 0.6rem;
		display: flex;
		align-items: center;
		background: linear-gradient(180deg, #fefeff 0%, #e6e6ff 100%);

		.codeImgStyle {
			width: 1.29rem;
			height: 1.29rem;

			img {
				width: 100%;
				height: 100%;
			}
		}

		.yaoQingStyle {
			color: #000;
			font-size: 0.32rem;
			margin: 0.15rem 0 0.2rem;
		}

		.rightBtn {
			margin-right: 0.2rem;
		}
	}
}

.footerBtn {
	color: #ffffff;
	font-size: 0.58rem;
	width: 4.24rem;
	height: 1.54rem;
	padding-bottom: 0.14rem;
	border-radius: 0.77rem;
	background: #ff4b04 url(../../assets/imgs/share/VIP.png);
	background-size: 100% 100%;
	margin: 0 auto;
	span {
		font-weight: bold;
	}
}

// --------20200410[start]----------
.layoutBox {
	padding: 0;
	font-family: PingFangSC-Regular;
	position: relative;
	// top: 0.88rem;
	padding-top: 0.88rem;
	padding-top: calc(env(safe-area-inset-top) + 0.88rem);
	left: 0;
	width: 100%;
	// z-index: 9999;
	&::before {
		content: '';
		height: 5.59rem;
		height: calc(env(safe-area-inset-top) + 5.59rem);
		width: 100%;
		background: url(../../assets/imgs/new_color/yaoqing_bg.png) no-repeat center top;
		background-size: 100% 100%;
		display: inline-block;
		position: absolute;
		top: 0;
	}
	.shareAds {
		position: relative;
		z-index: 10;
		margin: 0 auto;
		margin-top: 0.64rem;
		margin-top: calc(0.64rem - env(safe-area-inset-top));
		// margin-bottom: 0.47rem;
		text-align: center;
		color: #ffffff;
		.big_txt {
			padding: 0 0.2rem;
			font-size: 0.6rem;
			padding-bottom: 0.2rem;
		}
		.small_txt {
			font-size: 0.24rem;
		}
	}
	.shareSteps {
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 0.88rem;
		padding: 0 0.64rem;
		margin-top: 1.07rem;
		.item {
			display: flex;
			flex-direction: column;
			align-items: center;
			.icon {
				font-size: 0.32rem;
				width: 0.89rem;
				height: 0.89rem;
				border-radius: 50%;
				background-color: #fff6c5;
				color: #efba0d;
				line-height: 0.89rem;
				text-align: center;
			}
			.txt {
				margin-top: 0.2rem;
				text-align: center;
				font-size: 0.28rem;
				width: 1.2rem;
			}
		}
		.item1 {
			display: flex;
			flex-direction: column;
			align-items: center;
			.icon {
				font-size: 0.32rem;
				width: 0.89rem;
				height: 0.89rem;
				border-radius: 50%;
				background-color: #fff6c5;
				color: #efba0d;
				line-height: 0.89rem;
				text-align: center;
			}
			.txt {
				margin-top: 0.2rem;
				text-align: center;
				font-size: 0.28rem;
				width: 1.68rem;
			}
		}
		.split {
			margin-top: -1.15rem;
			img {
				width: 0.38rem;
				height: 0.07rem;
			}
		}
	}
	.shareInfo {
		margin-top: 1.8rem;
		width: 100%;
		height: 5.24rem;
		background-image: url(../../assets/imgs/new_color/yaoqing_2.png);
		background-size: 100% 100%;
		background-repeat: no-repeat;
		position: relative;
		z-index: 88;
		.top {
			padding: 0 0.76rem;
			padding-top: 0.5rem;
		}
		.item {
			display: flex;
			justify-content: space-between;
			align-items: center;
			color: #333333;
			font-size: 0.24rem;
			.item_left {
				display: flex;
				align-items: center;
			}
			&:last-child {
				margin-bottom: 0;
			}
			.inp {
				font-family: PingFangSC-Medium;
				// width: 1.8rem;
				font-size: 0.32rem;
				color: #333;
				height: 0.7rem;
				line-height: 0.71rem;
				padding: 0 0.1rem 0 0.35rem;
				// background: #F6F7F9;
				// border-radius: .34rem;
				display: flex;
				justify-content: space-between;
				.link {
					width: 2.5rem;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}
			}
			.copy {
				width: 1.2rem;
				height: 0.41rem;
				font-size: 0.24rem;
				color: #efba0d;
				border: 1px solid #efba0d;
				border-radius: 0.325rem;
			}
		}
	}
	.shareReward {
		padding: 0.25rem;
		background: #ffffff;
		border-radius: 0.12rem;
		.caption {
			height: 0.5rem;
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 0.15rem;
			h3 {
				font-size: 0.32rem;
			}
		}
	}
}
.posterLayout {
	position: fixed;
	z-index: 98;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: rgba(4, 4, 15, 0.4);
}
.posterCode {
	position: fixed;
	z-index: 99;
	left: 1.02rem;
	right: 1.02rem;
	top: 50%;
	width: 5.47rem;
	height: 6.06rem;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	background-image: url(../../assets/imgs/new_color/mianduimian.png);
	background-size: 100% 100%;
	background-repeat: no-repeat;
	.title {
		font-family: PingFangSC-Regular;
		color: #333333;
		font-size: 0.32rem;
		margin: 0 auto;
		text-align: center;
		padding-top: 0.62rem;
		font-weight: bold;
	}
	.poster {
		img {
			display: block;
			width: 100%;
		}
		.code {
			margin-top: 0.58rem;
			margin-left: 1.07rem;
			width: 3.32rem;
			height: 3.36rem;
		}
	}
	.close {
		width: 0.8rem;
		height: 0.8rem;
		background: url(../../assets/imgs/share/icon_shut.png) no-repeat center center;
		background-size: 0.6rem 0.6rem;
		margin: 1.5rem auto 0;
	}
}
.bottom {
	padding: 0.25rem;
	display: flex;
	justify-content: space-between;
	.btn {
		color: #333333;
		font-size: 0.32rem;
		text-align: center;
		width: 3.26rem;
		height: 0.9rem;
		line-height: 0.9rem;
		background: #fad538;
		border-radius: 0.16rem;
	}
}
// --------20200410[start]----------

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;